<template>
  <div id="info-flow">
    这是信息流界面
  </div>
</template>

<script>
export default {
  name: "InfoFlow"
}
</script>

<style scoped>

</style>