<template>
  <div id="personalization">
    这是个性化页面
  </div>
</template>

<script>
export default {
  name: "Personalization"
}
</script>

<style scoped>

</style>